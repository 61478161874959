import * as React from "react";
// import { DataGrid, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";

import {
  DataGridPro,
  GridRow,
  GridColumnHeaders,
  GridActionsCellItem,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ShareIcon from "@mui/icons-material/Share";
import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Box } from "@mui/system";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";

import "../css/coupon.css";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { RiDeleteBin6Line, RiPencilLine } from "react-icons/ri";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import ClipboardCopy from "../Modals/ClipboardCopy";
import * as ApiCall from "../../../../../API/ApiCalling";
import {
  randomCreatedDate,
  randomUpdatedDate,
} from "@mui/x-data-grid-generator";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import ModalDecider from "./ModalDecider";

import { changeName } from "./../Global/FileResponseChanger";
import { Controller, useForm } from "react-hook-form";
import RenameDetailsModal from "../Modals/RenameDetailsModal";

export default function DetailsView({
  post,
  handleClick,
  filteredData = [],
  datacenterurl,
  connname,
  apikey,
  orgid,
  settingId,
  setDetailsViewPost,
  moveCopyData,
  pasteDatainDetailsView,
  perPage,
  fromSearchView,
  settingUrl 
}) {
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const [sharedLink, setSharedLink] = React.useState("");
  const [sharedFileOpen, setSharedOpen] = React.useState(false);
  const [fileOpen, setFileOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [profileImage, setProfileImage] = React.useState(false);
  const apiRef = useGridApiRef();
  const snap = useSnapshot(widState);
  const { handleSubmit, control, reset } = useForm();

  React.useEffect(() => {
    const tempRows = JSON.parse(JSON.stringify(filteredData))?.map(
      (item, index) => {
        return {
          id: item?.id,
          name: item?.name,
          type: item?.type,
          size: item?.size,
          modified_by: item?.modified_by,
          last_modified_time: item?.last_modified_time,
          created_time: item?.created_time,
          extn: item?.extn,
          parent_id: item?.parent_id,
        };
      }
    );
    setRows((prev) => tempRows);
  }, [filteredData]);

  const handleShareFileFolder = async (file) => {
    snap.setLoading(true);
    setShareOpen((prev) => false);
    setOpen((prev) => false);
    let snackMessage = {
      bool: true,
      type: "error",
      message: "Request failed",
    };

    try {
      const response = await ApiCall.shareFullAccess(
        {},
        file,
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingId
      );

      if (response?.error) {
        // ;

        snackMessage = {
          bool: true,
          type: "error",
          message: response?.error?.errors[0]?.title,
        };

        snap.setSnackbar(snackMessage);
        snap.setLoading(false);
        return;
      }

      snackMessage = {
        bool: true,
        type: "success",
        message: "File shared successfully",
      };

      setSharedLink((prev) => response?.data?.data?.attributes?.link);
      setSharedOpen((prev) => true);

      snap.setSnackbar(snackMessage);
    } catch (error) {
      snap.setSnackbar(snackMessage);
    }
    snap.setLoading(false);
  };

  const openImagePdf = async (file) => {
    try {
      snap.setLoading(true);
      let res = await ApiCall.getImageResponse(
        file,
        connname,
        orgid,
        apikey,
        datacenterurl
      );

      var blobFile = new Blob([res.data]);

      var fileURL = URL.createObjectURL(blobFile);
      widState.setDownloadedImage(settingId, file, fileURL);
      setFile((prev) => file);
      setProfileImage((prev) => fileURL);
      setFileOpen((prev) => true);
    } catch (error) {
      return;
    }
  };

  const downloadFile = async (file) => {
    try {
      snap.setLoading(true);
      let response = await ApiCall.getImageResponse(
        file,
        connname,
        orgid,
        apikey,
        datacenterurl
      );
      // zohosheet
      let url = "";
      if (file?.type == "spreadsheet" || file?.type == "zohosheet") {
        url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "text/csv;charset=utf-8",
          })
        );
      } else {
        url = window.URL.createObjectURL(new Blob([response.data]));
      }
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file?.name); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {}
    snap?.setLoading(false);
  };

  const getUserImage = async (file) => {
    console.log({ file });
    if (
      widState.settingData?.[settingId]?.downloadUrls.hasOwnProperty(file?.id)
    ) {
      snap.setLoading(false);
      setFile((prev) => file);
      setFileOpen((prev) => true);
      setProfileImage(
        (prev) => widState.settingData?.[settingId]?.downloadUrls?.[file?.id]
      );
    } else if (file?.type != "image" && file?.type != "pdf") {
      setFile((prev) => file);
      setFileOpen((prev) => true);
      setProfileImage((prev) => "");
    } else {
      await openImagePdf(file);
      // window.open(fileURL);
    }

    snap.setLoading(false);
  };

  const handleClickOpen = (file) => {
    setFile((prev) => file || {});
    setOpen((prev) => true);
  };

  const handleClose = () => {
    setOpen(false);
    setShareOpen(false);
    // setFile((prev) => null);
    setSharedOpen((prev) => false);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteOpen((prev) => false);
  };

  const deleteData = () => {
    snap.setLoading(true);
    handleCloseDeleteDialog();
    ApiCall.deleteFileFolder(
      file,
      connname,
      orgid,
      apikey,
      datacenterurl,
      settingId
    )
      .then((response) => {
        if (response?.data) {
          let lastIndex =
            widState?.settingData?.[settingId]?.breadCrumbs?.length - 1;
          let lastIndexId =
            widState?.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
          let xArray = JSON.parse(
            JSON.stringify(
              widState?.settingData?.[settingId]?.previousData?.[lastIndexId]
            )
          ).filter((previousFile) => previousFile.id != file.id);

          // setSnackOpen(true);
          snap.setSnackbar({
            bool: true,
            type: "success",
            message: "File deleted successfully",
          });
          // setPost(xArray);
          setDetailsViewPost({ id: file?.parent_id }, xArray);
          // widState?.setAddItemSettingData(settingId, lastIndexId, xArray);
        } else {
          snap.setSnackbar({
            bool: true,
            type: "error",
            message:
              "Couldn't delete the file. Please check your internet connection or other setting.",
          });
        }

        snap.setLoading(false);
      })
      .catch((error) => {
        // alert(error);

        // if (tempView) {
        //   snap.setViewSettingData(settingId, true);
        // }
        snap.setSnackbar({
          bool: true,
          type: "error",
          message:
            "Couldn't delete the file. Please check your internet connection or other setting.",
        });
        snap.setLoading(false);
      });
  };

  const onSubmit = async (data) => {
    snap.setLoading(true);
    setShareOpen((prev) => false);
    setOpen((prev) => false);
    let snackMessage = {
      bool: true,
      type: "error",
      message: "Request failed",
    };

    try {
      if (data?.email) {
        const response = await ApiCall.shareFileFolder(
          {},
          file,
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingId
        );
        if (response?.error) {
          snackMessage = {
            bool: true,
            type: "error",
            message: response?.error?.errors[0]?.title,
          };

          snap.setSnackbar(snackMessage);
          snap.setLoading(false);
          return;
        }

        snackMessage = {
          bool: true,
          type: "success",
          message: "File shared successfully",
        };

        setSharedLink((prev) => response?.data?.data?.attributes?.link);
        setSharedOpen((prev) => true);

        // // ;
      } else if (data?.downloadOnly) {
        try {
          const response = await ApiCall.shareFileFolder(
            {},
            data,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingId
          );

          if (response?.error) {
            snackMessage = {
              bool: true,
              type: "error",
              message: response?.error?.errors?.[0]?.title,
            };

            snap.setSnackbar(snackMessage);
            snap.setLoading(false);
            return;
          }

          snackMessage = {
            bool: true,
            type: "success",
            message: "File shared successfully",
          };

          setSharedLink((prev) => response?.data?.data?.attributes?.link);
          setSharedOpen((prev) => true);
        } catch (downloadError) {}
      } else if (data?.name) {
        try {
          const response = await ApiCall.renameFileFolder(
            { name: data?.name },
            file,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingId
          );

          if (response?.data) {
            let tempResponse = changeName(response, file);
            // let tempResponse = changeName(response, response?.data)
            let tempArray = post.map((file) =>
              file.id !== tempResponse?.id ? file : tempResponse
            );
            widState?.setAddItemSettingData(
              settingId,
              tempResponse.parent_id,
              tempArray
            );
            snackMessage = {
              bool: true,
              type: "success",
              message: "Renamed successfully",
            };
            setDetailsViewPost({ id: file?.parent_id }, tempArray);
            // setPost(tempArray);
          } else {
            snackMessage = {
              bool: true,
              type: "error",
              message: response?.error,
            };
          }
        } catch (renameError) {}
        snap.setSnackbar(snackMessage);
        snap.setLoading(false);
      } else {
        try {
          const response = await ApiCall.shareFileFolder(
            {},
            data,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingId
          );

          if (response?.error) {
            snackMessage = {
              bool: true,
              type: "error",
              message: response?.error?.errors?.[0]?.title,
            };

            snap.setSnackbar(snackMessage);
            snap.setLoading(false);
            return;
          }

          snackMessage = {
            bool: true,
            type: "success",
            message: "File shared successfully",
          };

          setSharedLink((prev) => response?.data?.data?.attributes?.link);
          setSharedOpen((prev) => true);
        } catch (downloadError) {}
      }
      snap.setSnackbar(snackMessage);
    } catch (error) {
      snap.setSnackbar(snackMessage);
    }
    snap.setLoading(false);
  };

  const columns = React.useMemo(() => {
    const columns = [
      {
        field: "actions",
        type: "actions",
        flex: 0.2,
        getActions: (params) => {
          let actions = [
            <GridActionsCellItem
              sx={{ fontSize: 17, py: 0.2 }}
              icon={<RiPencilLine />}
              label="Rename"
              onClick={async () => {
                await handleClickOpen(params?.row);
              }}
              showInMenu
            />,
            <GridActionsCellItem
              sx={{ fontSize: 17, py: 0.2 }}
              icon={<ContentCopyIcon sx={{ fontSize: 17 }} />}
              label="Copy"
              onClick={() => {
                moveCopyData(null, {
                  type: "copy",
                  file: params.row,
                  parentFiles: post,
                });
              }}
              showInMenu
            />,
            <GridActionsCellItem
              sx={{ fontSize: 17, py: 0.2 }}
              icon={<DriveFileMoveIcon sx={{ fontSize: 17 }} />}
              label="Cut"
              onClick={() => {
                moveCopyData(null, {
                  type: "move",
                  file: params.row,
                  parentFiles:
                    widState?.settingData?.[settingId]?.previousData?.[
                      params?.row?.parent_id
                    ],
                });
              }}
              showInMenu
            />,
          ];
          if (params?.row?.type != "folder") {
            actions.push(
              <GridActionsCellItem
                sx={{ fontSize: 17, py: 0.2 }}
                icon={<DownloadOutlinedIcon sx={{ fontSize: 17 }} />}
                label="Download"
                onClick={async () => await downloadFile(params?.row)}
                showInMenu
              />
            );
            actions.push(
              <GridActionsCellItem
                sx={{ fontSize: 17, py: 0.2 }}
                icon={<OpenInNewIcon sx={{ fontSize: 17 }} />}
                label="Open in Workdrive"
                onClick={async () => {
                  window.open(
                    `https://workdrive.zoho.${
                      widState.dataCenterUrl.split("https://www.zohoapis.")?.[1]
                    }/file/${params?.row?.id}`,
                    "_blank",
                    "noreferrer"
                  );
                }}
                showInMenu
              />
            );
          }
          if (params?.row?.type == "folder") {
            actions.push(
              <GridActionsCellItem
                sx={{ fontSize: 17, py: 0.2 }}
                icon={<ContentPasteIcon sx={{ fontSize: 17 }} />}
                label="Paste"
                onClick={async () => {
                  await pasteDatainDetailsView(null, {
                    pasteFile: params.row,
                    pastePost:
                      widState?.settingData?.[settingId]?.previousData?.[
                        params?.row?.id
                      ],
                  });
                }}
                showInMenu
              />
            );
            actions.push(
              <GridActionsCellItem
                sx={{ fontSize: 17, py: 0.2 }}
                icon={<OpenInNewIcon sx={{ fontSize: 17 }} />}
                label="Open in Workdrive"
                onClick={() => {
                  window.open(
                    `https://workdrive.zoho.${
                      widState.dataCenterUrl.split("https://www.zohoapis.")?.[1]
                    }/folder/` +
                      params?.row?.id +
                      "?layout=list",
                    "_blank",
                    "noreferrer"
                  );
                }}
                showInMenu
              />
            );
          }
          actions = [
            ...actions,
            <GridActionsCellItem
              sx={{ fontSize: 17, py: 0.2 }}
              icon={<SendAndArchiveIcon sx={{ fontSize: 17 }} />}
              label="Ext. Share Link(Download Only)"
              onClick={async () => {
                await onSubmit({ ...params?.row, downloadOnly: true });
              }}
              showInMenu
            />,
            <GridActionsCellItem
              sx={{ fontSize: 17, py: 0.2 }}
              icon={<ShareIcon sx={{ fontSize: 17 }} />}
              label="Ext. Share Link(Full Access)"
              onClick={async () => {
                handleShareFileFolder(params?.row);
              }}
              showInMenu
            />,
            <GridActionsCellItem
              sx={{ fontSize: 17, py: 0.2 }}
              icon={
                <RiDeleteBin6Line
                  style={{ fontSize: 17, py: 0.2, color: "red" }}
                />
              }
              label="Delete"
              onClick={() => {
                setFile((prev) => params?.row);
                setDeleteOpen((prev) => true);
              }}
              showInMenu
            />,
          ];
          return actions;
        },
      },
      {
        field: "name",
        headerName: "Name",
        type: "string",
        flex: 1.5,
      },
      { field: "type", headerName: "Type", type: "string", flex: 1 },

      {
        field: "modified_by",
        headerName: "Modified By",
        type: "string",
        flex: 1,
      },
      {
        field: "last_modified_time",
        headerName: "Last Modified Time",
        type: "dateTime",
        flex: 1,
        valueGetter: ({ value }) => value && new Date(value),
      },
      {
        field: "created_time",
        headerName: "Creation Time",
        type: "dateTime",
        flex: 1,
        valueGetter: ({ value }) => value && new Date(value),
      },
    ];
    let index = 3;
    let sizeColumn = {
      field: "size",
      headerName: "Size (Byte)",
      type: "string",
      flex: 1,
    };
    if (!fromSearchView) {
      columns.splice(index, 0, sizeColumn);
    }
    return columns;
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <ModalDecider
        open={fileOpen}
        setOpen={setFileOpen}
        profileImage={profileImage}
        file={file}
        datacenterurl={datacenterurl}
        settingId={settingId}
        connname={connname}
        orgid={orgid}
        apikey={apikey}
        settingUrl={settingUrl}
      />

      <RenameDetailsModal
        open={open}
        defaultValue={file?.name}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        control={control}
        onSubmit={onSubmit}
        file={file}
      />

      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
              padding: "10px",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "23px", fontWeight: 500 }}
        >
          {"Rename"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              defaultValue={file?.name?.split(".")?.[0] || ""}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="standard-basic"
                  variant="standard"
                  {...field}
                  fullWidth
                />
              )}
            />

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ marginTop: "10px", mr: 2, color: "#1976D2" }}
                onClick={handleClose}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                sx={{ marginTop: "10px", bgcolor: "#1976D2" }}
                type="submit"
                variant="contained"
              >
                Rename
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog> */}

      <Dialog
        open={deleteOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
              padding: "10px",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "23px", fontWeight: 500 }}
        >
          {"Delete Item"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              pb: 2,
            }}
          >
            Do you really want to delete this item?
          </Box>
          <div style={{ display: "flex", justifyContent: "end", gap: 3 }}>
            <Button
              sx={{ marginTop: "10px", mr: 2, color: "#1976D2" }}
              onClick={handleCloseDeleteDialog}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginTop: "10px" }}
              variant="contained"
              color="error"
              onClick={deleteData}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={shareOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="share-popup"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
              padding: "10px",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "23px", fontWeight: 500 }}
        >
          {"Share Item"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => {
                // // ;
                return (
                  <TextField
                    id="standard-basic"
                    // variant= "standard"
                    {...field}
                    error={
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        field?.value
                      )
                        ? false
                        : true
                    }
                    variant="standard"
                    helperText={
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        field?.value
                      )
                        ? "You are good to go"
                        : "Enter a valid email"
                    }
                    fullWidth
                  />
                );
              }}
            />

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ marginTop: "10px", mr: 2, color: "#1976D2" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{ marginTop: "10px", bgcolor: "#1976D2" }}
                type="submit"
                variant="contained"
              >
                Share
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={sharedFileOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              // maxWidth: "400px", // Set your width here
              padding: "10px",
              wordWrap: "break-word",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "23px", fontWeight: 500 }}
        >
          {"Share Item"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            sx={{ padding: "15px", backgroundColor: "#001E3C", color: "#fff" }}
          >
            <Grid xs={11}>{sharedLink}</Grid>
            <Grid xs={1} sx={{ paddingLeft: "5px" }}>
              <ClipboardCopy copyText={sharedLink} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Box sx={{ width: "100%", height: fromSearchView && 390 }}>
        <DataGridPro
          columns={columns}
          rows={rows}
          // height={800}
          // rowHeight={30}
          pagination={{
            paginationModel: {
              pageSize: perPage || widState.pageSize || 10,
              page: 0,
            },
          }}
          apiRef={apiRef}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: perPage || widState.pageSize || 10,
                page: 0,
              },
            },
          }}
          disableColumnMenu={true}
          disableColumnSelector={true}
          density={"compact"}
          pageSizeOptions={
            fromSearchView ? [8, 10, 15, 25, 50, 100] : [10, 15, 25, 50, 100]
          }
          rowSelection={false}
          disableColumnResize={false}
          // components={{
          //   Row: rows,
          //   ColumnHeaders: columns,
          // }}
          onCellDoubleClick={({
            cellMode,
            colDef,
            field,
            formattedValue,
            hasFocus,
            id,
            isEditable,
            row,
            rowNode,
            tabIndex,
            value,
          }) => {
            if (field == "name") {
              if (row?.type == "folder") {
                handleClick(
                  row,
                  widState?.settingData?.[settingId]?.previousData?.[row?.id]
                );
              } else {
                getUserImage(row);
              }
            }
          }}
        />
      </Box>
    </div>
  );
}
